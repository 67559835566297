import React, { useState } from 'react'
import { useRole } from 'hooks/auth'
import useSWRMutation from 'swr/mutation'
import { putFetcher } from 'api/swr'
import Loader from '@/components/ui/Loader'
import { useParams } from 'react-router-dom'
import { Label } from '@/components/ui/label'
import Selector from 'components/ui/Selector'
import { CptPrices } from 'config'

const UpdateCpt = ({ cpt }) => {
  const [price, setPrice] = useState({
    pro: cpt.pro || 0,
    iOSPro: cpt.iOSPro || 0,
  })
  const { tid } = useParams()
  const isAdmin = useRole(`admin`)

  const { trigger, isMutating } = useSWRMutation(`/drafts/${tid}`, putFetcher)

  const handleChangePro = (value, type) => {
    if (isMutating) return false
    setPrice((item) => {
      return { ...item, [type]: value }
    })
    trigger({
      key: type,
      value: { cid: cpt.id, price: value },
    })
  }

  if (!isAdmin) {
    return null
  }

  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-4 items-center gap-4">
        <Label htmlFor="name" className="text-right">
          Pro
        </Label>
        <Selector
          labelName="pro"
          value={price.pro}
          datas={CptPrices}
          update={(v) => handleChangePro(v, 'pro')}
          width="w-[70px]"
          className="col-span-3"
        />
      </div>
      <div className="grid grid-cols-4 items-center gap-4">
        <Label htmlFor="name" className="text-right">
          iOS pro
        </Label>
        <Selector
          labelName="iOSPro"
          value={price.iOSPro}
          datas={CptPrices}
          update={(v) => handleChangePro(v, 'iOSPro')}
          width="w-[70px]"
          className="col-span-3"
        />
      </div>
      <div className="flex items-center justify-center">
        {isMutating && <Loader> updateing</Loader>}
      </div>
    </div>
  )
}

export default UpdateCpt
